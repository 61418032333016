import React, {useState, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Card, CardContent  } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { services } from '../services/api.tsx';
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import LoaderComponent from './Loader.js';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { animateScroll as scroll } from 'react-scroll';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Link from '@mui/material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReactRecaptcha3 from 'react-google-recaptcha3';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    height: '85%',
    bgcolor: 'background.paper',
    border: '2px solid #661d40',
    boxShadow: 24,
    p: 4,
    overflow: "hidden",
    flexDirection: "column",
    overflowY: "scroll",
    borderRadius: "10px",
    backdrop:'invisible'
  };

    const validateForm=(form)=>{
    let errors={};
    let regexRFC = /^([A-Z&]{1,4}\d{6}[A-Z0-9]{3}|[A-Z]{3}\d{6}[A-Z0-9]{3})$/i;
    let regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    if (form.rfc=== "") {
        errors.rfc = "Campo requerido"
    }else if(!regexRFC.test(form.rfc)){
        errors.rfc = "El RFC no cumple con el formato adecuado."
    }

    if (form.regimen_fiscal === "") {
        errors.regimen_fiscal = "Campo requerido"
    }

    if (form.razon_social === "") {
        errors.razon_social = "Campo requerido"
    }

    if (form.calle === "") {
        errors.calle = "Campo requerido"
    }

    if (form.num_ext=== "") {
        errors.num_ext = "Campo requerido"
    }

    if (form.estado === "") {
        errors.estado = "Campo requerido"
    }

    if (form.calle1 === "") {
        errors.calle1 = "Campo requerido"
    }

    if (form.municipio === "") {
        errors.municipio = "Campo requerido"
    }

    if (form.pais_origen === "") {
        errors.pais_origen = "Campo requerido"
    }

    if (form.pais_residencia === "") {
        errors.pais_residencia = "Campo requerido"
    }

    if (form.colonia=== "") {
        errors.colonia = "Campo requerido"
    }

    if (form.codigo_postal=== "") {
        errors.codigo_postal = "Campo requerido"
    }else if(form.codigo_postal.length !== 5){
        errors.codigo_postal = "Debe tener 5 digitos"
    }

    if (form.localidad=== "") {
        errors.localidad = "Campo requerido"
    }

    if (form.correo=== "") {
        errors.correo = "Campo requerido"
    }else if(!regexEmail.test(form.correo)){
        errors.correo = "No cumple con el formato adecuado."
    }

    if (form.conf_correo=== "") {
        errors.conf_correo = "Campo requerido"
    }else if(form.correo !== form.conf_correo){
        errors.conf_correo = "La confirmacion del correo y el correo no son iguales."
    }

    if (form.telefono=== "") {
        errors.telefono = "Campo requerido"
    }

    if (form.folio=== "") {
        errors.folio = "Campo requerido"
    }

    if (form.fecha=== "") {
        errors.fecha = "Campo requerido"
    }

    if (form.importe=== "") {
        errors.importe = "Campo requerido"
    }

    if (form.cfdi=== "") {
        errors.cfdi = "Campo requerido"
    }
    return errors;
};

export default function Form() {
    const [fecha_ini, setFechaIni] = useState(dayjs(new Date()));
    const secondary = {
        color: '#fff',
        backgroundColor: grey[500],
    };

    const texto = {
        color:'red',
    }

    const token = process.env.REACT_APP_TOKEN_RECAPTCHA;
    const [modal, setModal]=useState(false)
    const handleOpen = () => setModal(true);
    const handleClose = () => {setModal(false)};
    const [childModal, setChildModal]=useState(false)
    const handleOpenChild = () => setChildModal(true);
    const handleCloseChild = () => setChildModal(false);
    const [form, setForm]= useState({rfc:"",
    regimen_fiscal:"",
    razon_social:"",
    calle:"",
    num_ext:"",
    num_int:"",
    calle1:"",
    calle2:"",
    estado:"",
    municipio:"",
    pais_origen:"",
    pais_residencia: "",
    colonia:"",
    codigo_postal:"",
    localidad:"",
    correo:"",
    conf_correo:"",
    telefono:"",
    folio:"",
    fecha:dayjs(new Date()).format('YYYY-MM-DD'),
    importe:"",
    cfdi:""});

    const [errors, setErrors]=useState({rfc:"",
    regimen_fiscal:"",
    razon_social:"",
    calle:"",
    num_ext:"",
    num_int:"",
    calle1:"",
    calle2:"",
    estado:"",
    municipio:"",
    pais_origen:"",
    pais_residencia: "",
    colonia:"",
    codigo_postal:"",
    localidad:"",
    correo:"",
    conf_correo:"",
    telefono:"",
    folio:"",
    fecha:"",
    importe:"",
    cfdi:""});

    const [loader, setLoader] = useState({
        loading: false
    });

    const [isDisabled, setDisabled] = useState({
        rfc:false,
        regimen_fiscal:false,
        razon_social:false,
        calle:false,
        num_ext:false,
        num_int:false,
        calle1:false,
        calle2:false,
        estado:false,
        municipio:false,
        pais_origen:false,
        pais_residencia: false,
        colonia:false,
        codigo_postal:false,
        localidad:false,
        correo:false,
        conf_correo:false,
        telefono:false,
        folio:false,
        fecha:false,
        importe:false,
        cfdi:false
    });
    const [isHidden, setHidden] = useState({
        datosDomicilio:true,
        datosContacto:true,
        datosFacturacion:true,
        botonGenerales: false,
        botonDomicilio: false,
        botonContacto: false,
        botonFacturacion: false,
    });
    
    const handleFormaPago = (e) =>{
        const {name, value}= e.target;
        setDatos(prevDatos => ({
            ...prevDatos,
            form: {
                ...prevDatos.form,
                formaPago: value
            }
        }));
    }

    const handleChange = (e) =>{
        let {name,value}= e.target;
        let caracteresPermitidos='';
        switch (name) {
            case 'folio':
                value = value.trim()
                caracteresPermitidos = /^[Ww]?\d{0,20}$/;
                break;
            case 'importe':
                value = value.trim()
                caracteresPermitidos = /^\d+(\.\d{0,2})?$/;
                break;
            case 'rfc':
                value = value.trim()
                value = value.toUpperCase();
                break;
            case 'razon_social':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚüÜ-ÿÑñ]{1,254}$/;
                value = value.toUpperCase();
                break;
            case 'calle':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚÑñ-ÿÑñ]{1,254}$/;
                break;
            case 'num_ext': 
                caracteresPermitidos =  /^([a-zA-Z0-9'/-]{1,9})$/;
                break;
            case 'num_int':
                caracteresPermitidos =  /^([a-zA-Z0-9' /\-]{1,30})$/
                ;
                break;
            case 'calle1':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚ-ÿÑñ]{1,254}$/;
                break;
            case 'calle2':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚ-ÿÑñ]{1,254}$/;
                break;
            case 'colonia':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚ-ÿÑñ]{1,254}$/;
                break;
            case 'codigo_postal':
                caracteresPermitidos = /^([0-9]{1,5})$/;
                break;
            case 'localidad':
                caracteresPermitidos = /^[\w.,'\/ -ÀáéíóúÁÉÍÓÚ-ÿÑñ]{1,254}$/;
                break;
            case 'telefono':
                caracteresPermitidos = /^([0-9]{1,10})$/;
                break;
            default:
                break;
        }
            if(name === 'razon_social'){
                if (caracteresPermitidos.test(value) || value === '') {
                    setForm({
                        ...form,
                        [name]: value.toUpperCase(),
                    });
                }
    
                return false;
            }

        if (name === 'folio' || name === 'importe') {
            if (name === 'importe') {
                value = value.replace(/,/g, '');
            }

            

            if (caracteresPermitidos.test(value) || value === '') {
                setForm({
                    ...form,
                    [name]: value.toUpperCase(),
                });
            }

            return false;
        }

        if (name === 'calle' || name === 'num_ext' || name === 'num_int' || name === 'calle1' || name === 'calle2' || name === 'colonia' || name === 'codigo_postal' || name === 'localidad' || name === 'telefono') {
            if (caracteresPermitidos.test(value) || value === '') {
                setForm({
                    ...form,
                    [name]: value,
                });
            }
            return false;
        }
        setForm({
            ...form,
            [name]:value,
        });
    }

    const handleEstado = (e) =>{
        const {name, value}= e.target;
        obtenerMunicipios(value)
        setForm({
            ...form,
            estado:value,
        });
    }

    const handleFecha = (e) =>{
        const value= e.$d;
        setFechaIni(e)
        setForm({
            ...form,
            fecha:dayjs(value).format('YYYY-MM-DD'),
        });
    }

    const handleBlur=(e)=>{
        handleChange(e);
        setErrors(validateForm(form));
    }

    const handleBlurFecha=(e)=>{
        handleFecha(e);
        setErrors(validateForm(form));
    }

    const validarGenerales = e =>{
        if (errors.rfc || errors.rfc === "" || errors.regimen_fiscal || errors.regimen_fiscal === "" || errors.razon_social || errors.razon_social === "") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3400,
                iconColor: 'white',
                timerProgressBar: true,
                customClass: {
                    popup: 'colored-toast'
                  },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: 'Uno o varios campos estan incompletos o son incorrectos.'
              })
        }else{
            obtenerDomicilio();
            setHidden({...isHidden, datosDomicilio:false, botonGenerales: true});
            setDisabled({...isDisabled, rfc:true, regimen_fiscal:true, razon_social:true});
            scroll.scrollToBottom()
        }
    }

    const regresarDomicilio = e =>{
        setHidden({...isHidden, datosDomicilio:true, botonGenerales: false});
        setDisabled({...isDisabled, rfc:false, regimen_fiscal:false, razon_social:false});
        setErrors({...errors, calle:"Campo requerido", num_ext:"Campo requerido", calle1:"Campo requerido", estado:"Campo requerido", municipio:"Campo requerido", colonia:"Campo requerido", codigo_postal:"Campo requerido", localidad:"Campo requerido",})
        setForm({...form, calle:"", num_ext:"", num_int:"", calle1:"", calle2:"", estado:"", municipio:"", colonia:"", codigo_postal:"", localidad:"",});
    }
   
    const validarDomicilio = e =>{
        if (errors.calle || errors.calle === "" || 
        errors.num_ext || errors.num_ext === "" || 
        errors.num_int || errors.num_int === "" ||
        errors.calle1 || errors.calle1 === ""||
        errors.calle2 || errors.calle2 === ""||
        errors.estado || errors.estado === "" ||
        errors.municipio || errors.municipio === "" ||
        errors.pais_origen || errors.pais_origen === "" ||
        errors.pais_residencia || errors.pais_residencia === "" ||
        errors.colonia || errors.colonia === "" ||
        errors.codigo_postal || errors.codigo_postal === "" ||
        errors.localidad || errors.localidad === "") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3400,
                iconColor: 'white',
                timerProgressBar: true,
                customClass: {
                    popup: 'colored-toast'
                  },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: 'Uno o varios campos estan incompletos o son incorrectos.'
              })
        }else{
            setHidden({...isHidden, datosContacto:false, botonDomicilio:true});
            scroll.scrollToBottom()
            setDisabled({...isDisabled, calle:true, num_ext:true, num_int:true, calle1:true, calle2:true, estado:true, municipio:true, pais_origen:true, pais_residencia:true, colonia:true, codigo_postal:true, localidad:true});
        }
    }

    const regresarContacto = e =>{
        setHidden({...isHidden, datosContacto:true, botonDomicilio: false});
        setDisabled({...isDisabled, calle:false, num_ext:false, num_int:false, calle1:false, calle2:false, estado:false, municipio:false, pais_origen:false, pais_residencia:false, colonia:false, codigo_postal:false, localidad:false});    
    }

    const validarContacto = e =>{
        if (errors.correo || errors.correo === "" || errors.conf_correo || errors.conf_correo === "" || errors.telefono || errors.telefono === "") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3400,
                iconColor: 'white',
                timerProgressBar: true,
                customClass: {
                    popup: 'colored-toast'
                  },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: 'Uno o varios campos estan incompletos o son incorrectos.'
              })
        }else{
            obtenerCFDI(form.regimen_fiscal)
            setHidden({...isHidden, datosFacturacion:false, botonContacto: true});
            setDisabled({...isDisabled, correo:true, conf_correo:true, telefono:true});
            scroll.scrollToBottom()
        }
    }

    const alertGenerar = e =>{
        Swal.fire({
            title: '¿Estás seguro que tu información es correcta?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText:'Cancelar',
            customClass: {
                container: 'my-swal',
                confirmButton: 'pill',
                cancelButton: 'secondary'
            }
        }).then((result) => {
            if (result.isConfirmed) {
              generarFactura()
            }
        })
    }

    const regresarDatosFacturacion = e =>{
        setHidden({...isHidden, datosFacturacion:true, botonContacto: false});
        setDisabled({...isDisabled, correo:false, conf_correo:false, telefono: false});    
    }

    const validarDatosFacturacion = (e) =>{
        setDatos({...datos, domicilio: 3});
        if (errors.folio || errors.folio === "" 
        || errors.fecha || errors.fecha === "" 
        || errors.importe || errors.importe === "" 
        || errors.cfdi || errors.cfdi === "") {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3400,
                iconColor: 'white',
                timerProgressBar: true,
                customClass: {
                    popup: 'colored-toast'
                  },
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'error',
                title: 'Uno o varios campos estan incompletos o son incorrectos.'
              })
        }else{
            obtenerRecibo()
        }
    }

    const terminar = e =>{
        window.location.reload(false)
    }

    const inputReference = useRef(null);
    const captchaRef = useRef(null); 
    const [regimenes, setRegimenes] = useState([]);
    const [domicilio, setDomicilio] = useState([]);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [paises, setPaises] = useState([]);
    const [cfdi, setCFDI] = useState([]);
    const [datos, setDatos] = useState([]);
    const [mensaje, setMensaje] = useState([]);
    const [links, setLinks] = useState([]);

    const generarFactura = async () => { 
        try {
            setLoader({ loading: true })
            const recaptcha_token = await obtenerToken()
            const consulta={
                folio: datos.form.folio, 
                importe: datos.form.importe, 
                fechapago: datos.form.fecha,
                idUsoCfdi: datos.form.cfdi,
                RFC: datos.form.rfc,
                RazonSocial: datos.form.razon_social,
                NombreComercial: datos.form.razon_social,
                IdRegimenFiscal: datos.form.regimen_fiscal,
                Calle: datos.form.calle,
                NumeroExterno: datos.form.num_ext,
                NumeroInterno:datos.form.num_int,
                EntreCalle1: datos.form.calle1,
                EntreCalle2: datos.form.calle2,
                IdcCP: datos.form.codigo_postal,
                IdMunicipio: datos.form.municipio,
                Telefono: datos.form.telefono,
                email: datos.form.correo,
                PaisOrigen: datos.form.pais_origen,
                PaisResidencia: datos.form.pais_residencia,
                IdEstado: datos.form.estado,
                Localidad: datos.form.localidad,
                Colonia: datos.form.colonia,
                recaptcha_token: recaptcha_token, 
                metodoPago:datos.form.formaPago
            }
            const dataLinks = await services({ method: "POST", service: "GenerarXML", body: consulta })
            if (dataLinks.status) {
                setLinks({...links, pdf:dataLinks.data.pdf, xml:dataLinks.data.xml, zip:dataLinks.data.zip})
                handleOpenChild();
            }else{
                console.log(dataLinks)
                Swal.fire({
                    title: 'Error!',
                    text:  dataLinks.mensaje ? dataLinks.mensaje : dataLinks.error,
                    icon: 'error',
                    showConfirmButton: false,
                    showCloseButton:true,
                    customClass: {
                        container: 'my-swal'
                    }
                })
            }
            setLoader(false)            
        } catch (error) {
            console.log(error);  
        } 
    };

    const obtenerRegimenes = async () => { 
        try {

            const dataRegimenes = await services({ method: "GET", service: "regimenes-fiscales", body: null })
            setRegimenes({...regimenes, regimenes: dataRegimenes.data ? dataRegimenes.data : []});
        } catch (error) {
            console.log(error);  
        } 
    };

    const obtenerRecibo = async () => { 
        try {
            setLoader({ loading: true })
            const cfdi = await services({ method: "GET", service: `usosCFDI/${form.regimen_fiscal}`, body: null })
            const cfdi_desc = cfdi.data.filter((cfdi) => {return cfdi.Id === form.cfdi})
            const recaptcha_token = await obtenerToken()
            const consulta={folio: form.folio, importe: form.importe, fechapago: form.fecha, recaptcha_token: recaptcha_token}
            const dataRecibo = await services({ method: "POST", service: "ConsultaRecibo", body: consulta })
            const alo = regimenes.regimenes.filter((regimen) => regimen.Id === form.regimen_fiscal)
            setDatos({...datos, recibo: dataRecibo.data ? dataRecibo.data : [], form: {...form, cfdi_descripcion: cfdi_desc[0].Descripcion, regimen_fiscal_descripcion:alo[0].Descripcion, formaPago: dataRecibo.data?.TB_ConsultaRecibo?.FormaPago ? dataRecibo.data.TB_ConsultaRecibo.FormaPago : '01'}});
            setLoader({ loading: false })
            if (dataRecibo.status === 200) {
                handleOpen()
            }else{
                const Alert = Swal.mixin({
                    customClass: {
                        popup: 'colored-toast'
                    },
                })
                Alert.fire({
                    title: 'Error!',
                    text: dataRecibo.mensaje ? dataRecibo.mensaje : dataRecibo.error,
                    icon: 'error',
                    showConfirmButton: false,
                    showCloseButton:true
                  })
            }
        } catch (error) {
            console.log(error);  
        }finally{
        }
    };

    const obtenerToken= async () => {
        return  await ReactRecaptcha3.getToken()
    };

    const obtenerDomicilio = async () => {
        try {
            setLoader({ loading: true })
            const recaptcha_token = await obtenerToken()
            const datos = {RFC: form.rfc, IdRegimenFiscal: form.regimen_fiscal, RazonSocial: form.razon_social, recaptcha_token: recaptcha_token }
            const dataDomicilio = await services({ method: "POST", service: "Contribuyente/datos-fiscales", body: datos })
            setDomicilio({...domicilio, domicilio: dataDomicilio.data ? dataDomicilio.data : []});

            if (dataDomicilio.data) { 
                if (!dataDomicilio.data.mensaje) {
                    obtenerEstados()
                    obtenerMunicipios(dataDomicilio.data.IdEstado)
                    obtenerPaises()
                    setForm({
                        ...form,
                        calle: dataDomicilio.data.Calle,
                        num_ext: dataDomicilio.data.NumeroExterno,
                        num_int: dataDomicilio.data.NumeroInterno,
                        calle1: dataDomicilio.data.EntreCalle1,
                        calle2: dataDomicilio.data.EntreCalle2,
                        estado: dataDomicilio.data.IdEstado,
                        municipio: dataDomicilio.data.IdMunicipio,
                        pais_origen: dataDomicilio.data.PaisOrigen,
                        pais_residencia: dataDomicilio.data.PaisResidencia,
                        colonia: dataDomicilio.data.Colonia,
                        codigo_postal: dataDomicilio.data.IdcCP,
                        localidad: dataDomicilio.data.Localidad,
                    })
                    setErrors(validateForm({
                        ...form,
                        calle: dataDomicilio.data.Calle,
                        num_ext: dataDomicilio.data.NumeroExterno,
                        num_int: dataDomicilio.data.NumeroInterno,
                        calle1: dataDomicilio.data.EntreCalle1,
                        calle2: dataDomicilio.data.EntreCalle2,
                        estado: dataDomicilio.data.IdEstado,
                        municipio: dataDomicilio.data.IdMunicipio,
                        pais_origen: dataDomicilio.data.PaisOrigen,
                        pais_residencia: dataDomicilio.data.PaisResidencia,
                        colonia: dataDomicilio.data.Colonia,
                        codigo_postal: dataDomicilio.data.IdcCP,
                        localidad: dataDomicilio.data.Localidad,
                    }))
                    setLoader({loading: false})
                }else{
                    setMensaje({...mensaje, mensaje: dataDomicilio.data.mensaje})
                    setLoader({loading: false})
                    obtenerEstados()
                    obtenerPaises()
                }
            }else{
                setLoader({loading: false})
                obtenerEstados()
                obtenerPaises()
            }
        } catch (error) {
            console.log(error);  
        } 
    };
    const obtenerEstados = async () => { 
        try {
            const dataEstados = await services({ method: "GET", service: "estados", body: null })
            setEstados({...estados, estados: dataEstados.data ? dataEstados.data : []});
        } catch (error) {
            console.log(error);  
        } 
    };
    const obtenerPaises = async () => { 
        try {
            const dataPaises = await services({ method: "GET", service: "Paises", body: null })
            setPaises({...paises, paises: dataPaises.data ? dataPaises.data : []});
        } catch (error) {
            console.log(error);  
        } 
    };
    const obtenerMunicipios = async (idEstado) => { 
        try {
            const dataMunicipios = await services({ method: "GET", service: `municipios/${idEstado}`, body: null })
            setMunicipios({...municipios, municipios: dataMunicipios.data ? dataMunicipios.data : []});
        } catch (error) {
            console.log(error);  
        } 
    };
    const obtenerCFDI = async (idRegimenFiscal) => { 
        try {
            const dataCFDI = await services({ method: "GET", service: `usosCFDI/${idRegimenFiscal}`, body: null })
            setCFDI({...cfdi, cfdi: dataCFDI.data ? dataCFDI.data : []});
        } catch (error) {
            console.log(error);  
        } 
    };
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            obtenerRegimenes()
        }

        ReactRecaptcha3.init(token).then(
            (status) => {
            }
        );

        return () => {
            isMounted = false
        }
    }, [])
    
    return (
    <Box>
        {loader.loading &&
            <LoaderComponent/>
        }
        <Card sx={{width:'75%', margin: 'auto', marginTop: 2}}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0.5} columns={12} className='borderBottom--gris text-secondary GibsonBold'>
                        <Grid xs={4} sm={1}>
                            <PersonIcon sx={{width:50, height:50}}></PersonIcon>
                        </Grid>
                        <Grid xs={8} sm={10} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography  variant="h5">
                                Datos Generales
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField label="RFC" sx={{marginTop:2}} 
                                   disabled={isDisabled.rfc} id="rfc" inputProps={{ maxLength: 13 }} name="rfc" value={form.rfc} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.rfc && <Typography sx={texto}>{errors.rfc}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="regimen_fiscal">Régimen Fiscal</InputLabel>
                                <Select disabled={isDisabled.regimen_fiscal}
                                labelId="regimen_fiscal"
                                id="regimen_fiscal"
                                value={form.regimen_fiscal}
                                label="Regimen Fiscal"
                                name="regimen_fiscal"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                    {regimenes.regimenes?.map(item => (
                                    <MenuItem value={item.Id}>{item.Descripcion}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.regimen_fiscal && <Typography sx={texto}>{errors.regimen_fiscal}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField disabled={isDisabled.razon_social} label="Razón Social" 
                                    id="razon_social" name="razon_social" value={form.razon_social} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.razon_social && <Typography sx={texto}>{errors.razon_social}</Typography>}
                        </Grid>
                        <Grid item sx={{display:'flex', justifyContent: 'right'}} xs={12} md={12}>
                            {isHidden.botonGenerales ? null : <Button onClick={validarGenerales} id="continuar1" className='pill' endIcon={<ArrowForwardIosIcon />}>
                                Continuar
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
        <Card id="datos_domicilio" sx={{width:'75%', margin: 'auto', marginTop: 2}} hidden={isHidden.datosDomicilio}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0.5} columns={12} className='borderBottom--gris text-secondary GibsonBold'>
                        <Grid xs={4} sm={1}>
                            <HomeIcon sx={{width:50, height:50}}></HomeIcon>
                        </Grid>
                        <Grid xs={8} sm={10} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography  variant="h5">
                                Datos Domicilio
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Calle" sx={{marginTop:2}} 
                                    disabled={isDisabled.calle} id="calle" name="calle" value={form.calle} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.calle && <Typography sx={texto}>{errors.calle}</Typography>}
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField label="Número Exterior" sx={{marginTop:2}} 
                                    disabled={isDisabled.num_ext} id="num_ext" name="num_ext" value={form.num_ext} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.num_ext && <Typography sx={texto}>{errors.num_ext}</Typography>}
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl fullWidth>
                                <TextField label="Número Interior" sx={{marginTop:2}}
                                    disabled={isDisabled.num_int} id="num_int" name="num_int" value={form.num_int} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.num_int && <Typography sx={texto}>{errors.num_int}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Entre Calle" sx={{marginTop:2}} 
                                    disabled={isDisabled.calle1} id="calle1" name="calle1" value={form.calle1} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.calle1 && <Typography sx={texto}>{errors.calle1}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Y Calle" sx={{marginTop:2}} 
                                    disabled={isDisabled.calle2} id="calle2" name="calle2" value={form.calle2} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.calle2 && <Typography sx={texto}>{errors.calle2}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="estado">Estado</InputLabel>
                                <Select disabled={isDisabled.estado}
                                labelId="estado"
                                id="estado"
                                value={form.estado}
                                label="Estado"
                                name="estado"
                                onChange={handleEstado}
                                onBlur={handleBlur}>
                                    {estados.estados?.map(item => (
                                    <MenuItem value={item.Id}>{item.Entidad}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.estado && <Typography sx={texto}>{errors.estado}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="municipio">Municipio</InputLabel>
                                <Select disabled={isDisabled.municipio}
                                labelId="municipio"
                                id="municipio"
                                value={form.municipio}
                                label="Municipio"
                                name="municipio"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                    {municipios.municipios?.map(item => (
                                    <MenuItem value={item.id_municipio}>{item.municipio}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.municipio && <Typography sx={texto}>{errors.municipio}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="pais_origen">País de origen</InputLabel>
                                <Select disabled={isDisabled.pais_origen}
                                labelId="pais_origen"
                                id="pais_origen"
                                value={form.pais_origen}
                                label="Pais de origen"
                                name="pais_origen"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                    {paises.paises?.map(item => (
                                    <MenuItem value={item.Id}>{item.Descripcion}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.pais_origen && <Typography sx={texto}>{errors.pais_origen}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="pais_residencia">País de residencia</InputLabel>
                                <Select disabled={isDisabled.pais_residencia}
                                labelId="pais_residencia"
                                id="pais_residencia"
                                value={form.pais_residencia}
                                label="Pais de residencia"
                                name="pais_residencia"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                    {paises.paises?.map(item => (
                                    <MenuItem value={item.Id}>{item.Descripcion}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.pais_residencia && <Typography sx={texto}>{errors.pais_residencia}</Typography>}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormControl fullWidth>
                                <TextField label="Colonia"
                                    disabled={isDisabled.colonia} id="colonia" name="colonia" value={form.colonia} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.colonia && <Typography sx={texto}>{errors.colonia}</Typography>}
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <FormControl fullWidth>
                                <TextField maxLength='5' label="Código Postal"
                                   disabled={isDisabled.codigo_postal} id="codigo_postal" name="codigo_postal" value={form.codigo_postal} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.codigo_postal && <Typography sx={texto}>{errors.codigo_postal}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField label="Localidad"
                                    disabled={isDisabled.localidad} id="localidad" name="localidad" value={form.localidad} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.localidad && <Typography sx={texto}>{errors.localidad}</Typography>}
                        </Grid>
                        <Grid item sx={{display:'flex', justifyContent: 'right'}} xs={12} md={12}>
                            {isHidden.botonDomicilio ? null : 
                            <Box>
                                <Button onClick={regresarDomicilio} className='secondary' endIcon={<ArrowUpwardIcon />}>
                                    Regresar
                                </Button>
                                <Button onClick={validarDomicilio} className='pill' endIcon={<ArrowForwardIosIcon />}>
                                    Continuar
                                </Button>
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
        <Card id="datos_contacto" sx={{width:'75%', margin: 'auto', marginTop: 2}} hidden={isHidden.datosContacto}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0.5} columns={12} className='borderBottom--gris text-secondary GibsonBold'>
                        <Grid xs={4} sm={1}>
                            <EmailIcon sx={{width:50, height:50}}></EmailIcon>
                        </Grid>
                        <Grid xs={8} sm={10} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography  variant="h5">
                                Datos Contacto
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField ref={inputReference} label="Correo" sx={{marginTop:2}} 
                                    disabled={isDisabled.correo} id="correo" name="correo" value={form.correo} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.correo && <Typography sx={texto}>{errors.correo}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Confirmar Correo" sx={{marginTop:2}} 
                                    disabled={isDisabled.conf_correo} id="conf_correo" name="conf_correo" value={form.conf_correo} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.conf_correo && <Typography sx={texto}>{errors.conf_correo}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Teléfono" sx={{marginTop:2}} 
                                    disabled={isDisabled.telefono} id="telefono" name="telefono" value={form.telefono} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.telefono && <Typography sx={texto}>{errors.telefono}</Typography>}
                        </Grid>
                        <Grid item sx={{display:'flex', justifyContent: 'right'}} xs={12} md={12}>
                        {isHidden.botonContacto ? null : 
                            <Box>
                                <Button onClick={regresarContacto} className='secondary' endIcon={<ArrowUpwardIcon />}>
                                    Regresar
                                </Button>
                                <Button onClick={validarContacto} className='pill' endIcon={<ArrowForwardIosIcon />}>
                                    Continuar
                                </Button>
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
        <Card id="datos_facturacion" sx={{width:'75%', margin: 'auto', marginTop: 2}} hidden={isHidden.datosFacturacion}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0.5} columns={12} className='borderBottom--gris text-secondary GibsonBold'>
                        <Grid xs={4} sm={1}>
                            <AssignmentIcon sx={{width:50, height:50}}></AssignmentIcon>
                        </Grid>
                        <Grid xs={8} sm={10} sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography  variant="h5">
                                Datos Facturación
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Folio" sx={{marginTop:2}} 
                                    disabled={isDisabled.folio} id="folio" name="folio" value={form.folio} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.folio && <Typography sx={texto}>{errors.folio}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker format="DD/MM/YYYY" disableFuture disabled={isDisabled.fecha} label="Fecha" sx={{marginTop:2,width:'100%'}} id="fecha" name="fecha" value={fecha_ini} formatDate={(date) => dayjs(date).format('YYYY-MM-DD')} onChange={handleFecha} onBlur={handleBlurFecha}/>
                                </LocalizationProvider>
                                {errors.fecha && <Typography sx={texto}>{errors.fecha}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField label="Importe" sx={{marginTop:2}} 
                                    disabled={isDisabled.importe} id="importe" name="importe" value={form.importe} onChange={handleChange} onBlur={handleBlur}
                                />
                            </FormControl>
                            {errors.importe && <Typography sx={texto}>{errors.importe}</Typography>}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel id="cfdi" sx={{marginTop:2}}>CFDI</InputLabel>
                                <Select
                                sx={{marginTop:2}}
                                disabled={isDisabled.cfdi}
                                labelId="cfdi"
                                id="cfdi"
                                value={form.cfdi}
                                label="CFDI"
                                name="cfdi"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                                {cfdi.cfdi?.map(item => (
                                    <MenuItem value={item.Id}>{item.Descripcion}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            {errors.cfdi && <Typography sx={texto}>{errors.cfdi}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid item sx={{display:'flex', justifyContent: 'right'}} xs={12} md={12}>
                    {isHidden.botonFacturacion ? null : 
                        <Box>
                            <Button onClick={regresarDatosFacturacion} className='secondary' endIcon={<ArrowUpwardIcon />}>
                                Regresar
                            </Button>
                            <Button onClick={validarDatosFacturacion} className='pill' endIcon={<ArrowForwardIosIcon />}>
                                Continuar
                            </Button>
                        </Box>}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
        {<Modal
            open={modal} onClose={handleClose}>
            <Box sx={style} className="contenedorModalUrs">
                <IconButton size="large" onClick={handleClose} ><CloseRoundedIcon fontSize="inherit"></CloseRoundedIcon></IconButton>
                <Grid container spacing={1}>
                    <Grid item sx={{display:'flex', justifyContent: 'center'}} xs={12} md={12}>
                        <Typography className="text-guinda GibsonRegular" variant="h5">
                            ¿Está de acuerdo con los datos de su factura?
                        </Typography>
                    </Grid>
                    <Grid item sx={{display:'flex', justifyContent: 'center'}} xs={12} md={12}>
                        <Typography className="text-guinda GibsonRegular" variant="h5">
                            ¡Importante! Cuenta con 3 días hábiles para solicitar la cancelación de su factura
                        </Typography>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid sx={{ mt: 0.1, ml: 0.1 }} container spacing={2} columns={12}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} sm={3} md={5}>
                                <Typography variant="h6">
                                    Fecha:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9} md={7}>
                                <Typography  variant="h6">
                                    {datos?.form?.fecha}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="h6">
                                    Tipo de comprobante:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography  variant="h6">
                                    {datos?.recibo?.TB_ConsultaRecibo?.TipoComprobante}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} sm={5}>
                                <Typography  variant="h6">
                                    Folio/Serie:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography  variant="h6">
                                    {datos?.recibo?.TB_ConsultaRecibo?.Folio}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{ mt: 1, borderBottomWidth: 10, borderRadius: 2, bgcolor: '#4A001F'}}/>
                <Grid sx={{ mt: 0, ml: 0.1 }} container spacing={2} columns={12}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={3}>
                                <Typography variant="subtitle1">
                                    Razón Social:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="subtitle1">
                                    {datos?.form?.razon_social}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={3}>
                                <Typography variant="subtitle1">
                                    RFC:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="subtitle1">
                                    {datos?.form?.rfc}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={3}>
                                <Typography variant="subtitle1">
                                    Régimen:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="subtitle1">{datos?.form?.regimen_fiscal_descripcion}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={3}>
                                <Typography variant="subtitle1">
                                    Uso CFDI:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="subtitle1">
                                    {datos?.form?.cfdi} - {datos?.form?.cfdi_descripcion}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={3}>
                                <Typography variant="subtitle1">
                                    Domicilio:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={9}>
                                <Typography  variant="subtitle1">
                                    {`${datos?.form?.calle} ${datos?.form?.num_ext} ${datos?.form?.num_int} ${datos?.form?.codigo_postal}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ mt: 3, ml: 0.1}} container spacing={2} columns={12}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead sx={{ backgroundColor: '#65676b', color: '#fff' }}>
                                <TableRow >
                                    <TableCell className='text-white' align="center">Clave</TableCell>
                                    <TableCell className='text-white' align="center">No. Id</TableCell>
                                    <TableCell className='text-white' align="center">Descripción del Concepto</TableCell>
                                    <TableCell className='text-white' align="center">Cantidad</TableCell>
                                    <TableCell className='text-white' align="center">Clave Unidad</TableCell>
                                    <TableCell className='text-white' align="center">Unidad</TableCell>
                                    <TableCell className='text-white' align="center">Precio Unitario</TableCell>
                                    <TableCell className='text-white' align="center">Importe</TableCell>
                                    <TableCell className='text-white' align="center">Descuento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {datos?.recibo?.TB_ConsultaRecibo?.TB_CONCEPTOS?.map((row) => (
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {row.ClaveProdServ}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.NoIdentificacion}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.Descripcion}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.Cantidad}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.ClaveUnidad}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.Unidad}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {`$${row.ValorUnitario}`}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {`$${row.Importe}`}
                                    </TableCell>
                                    <TableCell align="right" component="th" scope="row">
                                        {`$${row.descuento}`}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid sx={{ mt: 1, ml: 0.1}} container spacing={2} columns={12}>
                    <Grid item xs={6} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Forma de pago:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <InputLabel id="forma_pago" sx={{marginTop:2}}>Metodo de pago</InputLabel>
                                            <Select
                                            sx={{marginTop:2}}
                                            labelId="forma_pago"
                                            id="forma_pago"
                                            value={datos?.form?.formaPago}
                                            label="forma_pago"
                                            name="forma_pago"
                                            onChange={handleFormaPago}>
                                                <MenuItem value={'01'}>01 - Efectivo</MenuItem>
                                                <MenuItem value={'02'}>02 - Cheque nominativo</MenuItem>
                                                <MenuItem value={'03'}>03 - Transferencia electrónica de fondos</MenuItem>
                                                <MenuItem value={'04'}>04 - Tarjeta de crédito</MenuItem>
                                                <MenuItem value={'05'}>05 - Monedero electrónico</MenuItem>
                                                <MenuItem value={'06'}>06 - Dinero electrónico</MenuItem>
                                                <MenuItem value={'08'}>08 - Vales de despensa</MenuItem>
                                                {/* <MenuItem value={'12'}>12 - Dación en pago</MenuItem> */}
                                                <MenuItem value={'13'}>13 - Pago por subrogación</MenuItem>
                                                <MenuItem value={'14'}>14 - Pago por consignación</MenuItem>
                                                <MenuItem value={'15'}>15 - Condonación</MenuItem>
                                                <MenuItem value={'17'}>17 - Compensación</MenuItem>
                                                {/* <MenuItem value={'23'}>23 - Novación</MenuItem> */}
                                                <MenuItem value={'24'}>24 - Confusión</MenuItem>
                                                <MenuItem value={'25'}>25 - Remisión de deuda</MenuItem>
                                                <MenuItem value={'26'}>26 - Prescripción o caducidad</MenuItem>
                                                <MenuItem value={'27'}>27 - A satisfacción del acreedor</MenuItem>
                                                <MenuItem value={'28'}>28 - Tarjeta de débito</MenuItem>
                                                <MenuItem value={'29'}>29 - Tarjeta de servicios</MenuItem>
                                                <MenuItem value={'30'}>30 - Aplicación de anticipos</MenuItem>
                                                <MenuItem value={'31'}>31 - Intermediario pagos</MenuItem>
                                                <MenuItem value={'99'}>99 - Por definir</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Método de pago:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {datos?.recibo?.TB_ConsultaRecibo?.MetodoPago}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                    <TableContainer sx={{ maxWidth: '98%' }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell >Subtotal</TableCell>
                                    <TableCell >${datos?.recibo?.TB_ConsultaRecibo?.Subtotal}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell >Descuento</TableCell>
                                    <TableCell >${datos?.recibo?.TB_ConsultaRecibo?.Descuento}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell >Total</TableCell>
                                    <TableCell >${datos?.recibo?.TB_ConsultaRecibo?.Total}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
                </Grid>
                <Grid sx={{ display:'flex', justifyContent: 'right', mt: 3, ml: 0.1}} container spacing={2} columns={12}>
                    <Button onClick={handleClose} variant="contained" className='secondary' startIcon={<CloseRoundedIcon />}>Cerrar</Button>
                    <Button onClick={alertGenerar} className='pill'>Generar Factura</Button>
                </Grid>
            </Box>
        </Modal>}
        {<Modal open={childModal}
                onClose={(event, reason) => {
                    if(reason === 'backdropClick') {
                        setChildModal(true);
                    }
                }}>
                    <Box sx={{ ...style, width: '50%', height: '70%',}} className="contenedorModalUrs">
                        <h2 >Generación Exitosa</h2>
                        <Grid fullWidth container sx={{mt:1}}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">Se envio al correo:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">{datos?.form?.correo}</Typography>
                            </Grid>
                        </Grid>
                        <Typography sx={{mt:1}} variant="subtitle2">Haga click para descargar su factura en fomato:</Typography>
                        <Grid container spacing={2} sx={{display:'flex', textAlign: 'center',mt:2}}>
                            <Grid item xs={4} >
                                <Link href={links.xml}><ReceiptLongIcon sx={{ fontSize: 40 }}/></Link>
                                <Typography variant="h6">XML</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href={links.pdf}><PictureAsPdfIcon sx={{ fontSize: 40 }}/></Link>
                                <Typography variant="h6">PDF</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Link href={links.zip}><FolderZipIcon sx={{ fontSize: 40 }}/></Link>
                                <Typography variant="h6">ZIP</Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display:'flex', justifyContent: 'right', mt: 3}} container spacing={2}>
                            <Button onClick={terminar} className='pill'>Terminar</Button>
                        </Grid>
                    </Box>
                </Modal>}
    </Box>
  );
}