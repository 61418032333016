
import { FaFacebookF } from 'react-icons/fa'
import { BsTwitter } from 'react-icons/bs'
import { AiOutlineInstagram } from 'react-icons/ai'
import IconUI from './ui/icon_ui'



 const Footer = () => {

    return (
        <>
            <div id="footer" className="shadow-sm bg-white border-top--rosa p-4 margin-on-search">
                <div className="container">


                    <div className='d-md-flex d-none  align-items-center justify-content-center'>
                        <div className='mensajeGobiernoNormal'>
                            <div className='text-gray text-small my-2' >© Desarrollado por <span className='text-guinda cursor-pointer' onClick={() => {
                                window.open('https://gobiernodigital.michoacan.gob.mx/', '_blank');
                            }}>Dirección General de Gobierno Digital</span> <br /> Gobierno del Estado de Michoacán 2024</div>

                          
                        </div>
                        <div>
                            <div className='redes text-center d-flex justify-content-center'>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://www.facebook.com/gobmichoacan/', '_blank');
                                }}>
                                    <IconUI size={'22px'}>
                                        <FaFacebookF />
                                    </IconUI>
                                </div>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://www.instagram.com/gobmichoacan/?hl=es', '_blank');
                                }}>
                                    <IconUI size={'25px'}>
                                        <AiOutlineInstagram />

                                    </IconUI>
                                </div>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://twitter.com/GobMichoacan', '_blank');
                                }}>
                                    <IconUI size={'22px'}>
                                        <BsTwitter />
                                    </IconUI>
                                </div>

                            </div>
                            <div className='mt-1'>
                                <div className='text-center text-small text-gray'>Enlaces de comunicación</div>
                                <a target='_blank' href='Aviso-de-Privacidad.pdf'>
                                    <div className='text-center enlace-login'>Aviso de Privacidad</div>
                                </a>
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className='text-gray text-big my-2' >#Honestidady<a target='_blank' href='AMED.pdf'>Trabajo</a></div>
                           
                        </div>
                    </div>
                    <div className='d-flex aling-items-center footer_responsive'>
                            <div className='text-gray text-center text-small my-2' >© Desarrollado por <span className='text-guinda cursor-pointer' onClick={() => {
                                window.open('https://gobiernodigital.michoacan.gob.mx/', '_blank');
                            }}>Dirección General de Gobierno Digital</span> <br /> Gobierno del Estado de Michoacán 2023</div>
                           
                    </div>
                    <div className='d-md-none d-flex  flex-column align-items-center justify-content-center'>
                        <div className='d-flex aling-items-center'>
                            <div className='text-gray text-center text-small my-2' >© Desarrollado por <span className='text-guinda cursor-pointer' onClick={() => {
                                window.open('https://gobiernodigital.michoacan.gob.mx/', '_blank');
                            }}>Dirección General de Gobierno Digital</span> <br /> Gobierno del Estado de Michoacán 2023</div>
                           
                        </div>
                        <div className='mt-4'>
                            <div className='redes text-center d-flex justify-content-center'>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://www.facebook.com/gobmichoacan/', '_blank');
                                }}>
                                    <IconUI size={'22px'}>
                                        <FaFacebookF />
                                    </IconUI>
                                </div>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://www.instagram.com/gobmichoacan/?hl=es', '_blank');
                                }}>
                                    <IconUI size={'25px'}>
                                        <AiOutlineInstagram />

                                    </IconUI>
                                </div>
                                <div className='me-2 icon_btn' onClick={() => {
                                    window.open('https://twitter.com/GobMichoacan', '_blank');
                                }}>
                                    <IconUI size={'22px'}>
                                        <BsTwitter />
                                    </IconUI>
                                </div>
                              
                            </div>
                            <div className='mt-1'>
                                {/* <div className='text-center text-small text-gray'>Correo Institucional</div> */}
                                <div className='text-center text-small text-gray'>Enlaces de comunicación</div>
                                <a target='_blank' href='Aviso-de-Privacidad.pdf'>
                                    <div className='text-center text-small text-gray'>Aviso de Privacidad</div>
                                </a>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </div>
            <div className='backgrounPlaca w-100'></div>
        </>
    )
}
export default Footer;