import React from 'react';
import Form from './components/Form';
// import Form from './components/mantenimiento';
import Navbar from './components/Navbar';
import Footer from './components/footer_component';
import {
  BrowserRouter as Router,
  useRoutes
} from "react-router-dom";
import Previsualizacion from './components/Previsualizacion';

/* 
  Cuando se integre el router favor de habilitar la siguiente linea:
const baseName = process.env.REACT_APP_ENV === 'development' ? '/factura-facil' : '/';

Tambien añadirla a la propiedad baseName del main router:
Ejemplo:
<Router basename={baseName}>
</Router>
*/

const RouterApp = () => {
  let routes = useRoutes([
    { path: "/", element: <Form /> }
  ])
  return routes;
}
function App() {

  return (
    <Router>
      <div style={{display: 'grid', gridTemplateRows: 'auto 1fr auto',minHeight: '100vh'}}>
        <Navbar/>
        <RouterApp/>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
