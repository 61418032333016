import Axios from "axios";
import {Server} from "./api.type";

const axios = Axios.create({
    baseURL: process.env.REACT_APP_URL_API,
});

export const services = ({method, service, body} : Server) => {
    const url = `${service}`;

    switch (method) {
        case "GET":


            return axios.get(url).then((r) => {
                return response(r)
            }).catch((err) => {
                return response(err.response);
            });


        case "POST":

            return axios.post(`${service}`, body).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });


        case "PUT":
            return axios.put(`${service}`, body).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        case "DELETE":
            return axios.delete(`${service}`).then((r) => {
                return response(r);
            }).catch((err) => {
                return response(err.response);
            });

        default:
            break;
    }
   
}




const response = (r:any) => {
   if (r.status === 200) {
        return { status: r.status, data: r.data }
    }else{
    
        return r.data
    }
}