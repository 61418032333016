import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import gifLogo1 from '../assets/img/Logo.gif'

const LoaderComponent = () => {
    return ( 
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5000 }}
        open={true}
      >
        <img src={gifLogo1} className="logoGif" />
      </Backdrop>    
    );
}
 
export default LoaderComponent;