import React from 'react'
// import img from '../assets/img/logo_completo.6610bbb4d89e0a19d89a.png'
// import img2 from '../assets/img/Logo.gif'
import img from '../assets/img/PNG HORIZONTAL.png'
import img2 from '../assets/img/FacturaFacilColor.png'

function Navbar() {
  return (
    <nav className="navbar bg-white borderBottom--Rosa">
        <div className="container">
          <a className="navbar-brand">
            <img src={img}  width="120"  />
            <span className="logo-text text-gray">Factura Fácil</span>
          </a>
          <img src={img2} width="70"/>
        </div>
      </nav>
  )
}

export default Navbar