import React, {useState, useEffect} from 'react'
import { Box, Card, CardContent, Grid, Typography  } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useLocation } from 'react-router-dom';
import { services } from '../services/api.tsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Previsualizacion = () => {
    const location = useLocation();
    console.log(location.state.recibo)
    const [regimenes, setRegimenes] = useState({});
    const obtenerRegimenes = async () => { 
        try {
            const dataRegimenes = await services({ method: "GET", service: "regimenes-fiscales", body: null })
            const alo = dataRegimenes.data.filter((regimen) => {
                return regimen.Id === location.state.form.regimen_fiscal
            })
            setRegimenes({...regimenes, Descripcion: alo[0].Descripcion})
        } catch (error) {
            console.log(error);  
        } 
    };
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            obtenerRegimenes()
        }
        return () => {
            isMounted = false
        }
    }, [])
    return (
    <Box>
        <Card sx={{width:'75%', margin: 'auto', marginTop: 2}}>
            <CardContent>
                    <Grid container spacing={1}>
                        <Grid item sx={{display:'flex', justifyContent: 'center'}} xs={12} md={12}>
                            <Typography className="text-guinda text-bolder" variant="h5">
                                ¿Está de acuerdo con los datos de su factura?
                            </Typography>
                        </Grid>
                        <Grid item sx={{display:'flex', justifyContent: 'center'}} xs={12} md={12}>
                            <Typography className="text-guinda text-bolder" variant="h5">
                                ¡Importante! Cuenta con 3 días habiles para solicitar la cancelación de su factura
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid sx={{ mt: 0.1, ml: 0.1 }} container spacing={2} columns={12}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography variant="h6">
                                        Fecha:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography  variant="h6">
                                        {location.state.form.fecha}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <Typography  variant="h6">
                                        Tipo de comprobante:
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography  variant="h6">
                                        {location.state.recibo.TB_ConsultaRecibo.TipoComprobante}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography  variant="h6">
                                        Folio/Serie:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography  variant="h6">
                                        {location.state.recibo.TB_ConsultaRecibo.Folio}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" sx={{ mt: 1, borderBottomWidth: 10, borderRadius: 2, bgcolor: '#4A001F'}}/>
                    <Grid sx={{ mt: 0, ml: 0.1 }} container spacing={2} columns={12}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h6">
                                        Razon Social:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography  variant="h6">
                                        {location.state.form.razon_social}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h6">
                                        RFC:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography  variant="h6">
                                        {location.state.form.rfc}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h6">
                                        Régimen:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                   <Typography  variant="h6">{regimenes.Descripcion}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h6">
                                        Uso CFDI:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography  variant="h6">
                                        {location.state.form.cfdi}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h6">
                                        Domicilio:
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography  variant="h6">
                                        {`${location.state.form.calle} ${location.state.form.num_ext} ${location.state.form.num_int} ${location.state.form.codigo_postal}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: 3, ml: 0.1}} container spacing={2} columns={12}>
                        <TableContainer sx={{ maxWidth: '98%' }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead sx={{ backgroundColor: '#65676b', color: '#fff' }}>
                                    <TableRow>
                                        <TableCell>Clave</TableCell>
                                        <TableCell >No. Id</TableCell>
                                        <TableCell >Descripción del Concepto</TableCell>
                                        <TableCell >Cantidad</TableCell>
                                        <TableCell >Clave Unidad</TableCell>
                                        <TableCell >Unidad</TableCell>
                                        <TableCell >Precio Unitario</TableCell>
                                        <TableCell >Importe</TableCell>
                                        <TableCell >Descuento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {(location.state.recibo.TB_ConsultaRecibo.TB_CONCEPTOS).map((row) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {row.ClaveProdServ}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.NoIdentificacion}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.Descripcion}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.Cantidad}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.ClaveUnidad}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.Unidad}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            {`$${row.ValorUnitario}`}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            {`$${row.Importe}`}
                                        </TableCell>
                                        <TableCell align="right" component="th" scope="row">
                                            {`$${row.descuento}`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
            </CardContent>
        </Card>
    </Box>
  )
}

export default Previsualizacion